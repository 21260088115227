body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.error {
  color: red;
}
.swal_btn_ok {
  background-color: #008080;
  color: #fff;
  font-size: 14px;
  padding: 4px 10px;
  border: none;
  outline: none;
  border-radius: 2px;
}

.login_sec {
  background-color: black;
}
.Login_bg {
  background-color: black;
  padding: 20px;
}
.about_sec {
  background-color: #121921;
  position: relative;
}

.login_sec{ position: relative;}
.circle_png{ position: absolute; left: 171px; bottom: 174px;}

.left_cut{ position: absolute; left: 0px; bottom: 0px;}

.boxes_png{ position:absolute; right: 20px; bottom: 0px; width: 120px;}

.Global_input {
  color: white;
}
.Global_input fieldset {
  border: 1px solid #63676f;
  border-radius: 10px;
  color: white;
}

.facility_box h1 {
  font-size: 22px;
  background-color: white;
  color: black;
  border-radius: 0px 40px 0px 40px;
  display: inline-block;
  padding: 5px 40px;
  margin-top: 20px;
}

.owl-theme .owl-dots .owl-dot.active span {
  width: 16px;
  height: 6px;
  background-color: #00ee00 !important;
}

.owl-theme .owl-dots .owl-dot span {
  width: 10px;
  height: 6px;
}

/* .bg_side{ background-color: black;} */

.sidebr-admin .MuiDrawer-paperAnchorDockedLeft {
  background: linear-gradient(165.29deg, #000000 27.41%, #00ee00 200.86%);
  border-right: 0px;
}

.simplebar-content a.Mui-selected {
  border-radius: 0px !important;
  background: linear-gradient(90deg, #00ee00 0%, #000000 100%);
}

.admin_header header {
  background-color: black;
}

.arrow_btn {
  background-color: #00ee00;
  width: 26px;
  height: 26px;
  border-radius: 100%;
}

.top_page {
  background: linear-gradient(90deg, #5af32c 0%, rgba(255, 255, 255, 0) 100%);
  display: inline-block;
  padding: 7px 66px 4px 5px;
}

.que_anyltics {
  background-color: black;
  border-radius: 30px;
  padding: 30px;
  margin-top: 40px;
}

.table_samepattern thead {
  background-color: black;
  color: white;
}

.table_samepattern thead th {
  color: white;
  text-wrap: nowrap;
}

.table_samepattern {
  background: #ffffff;
  border: 1px solid #eaecf0;
  box-shadow: 0px 0px 3px 1px rgba(102, 102, 102, 0.06);
  border-radius: 20px;
  overflow: hidden;
}

.MuiDrawer-paperAnchorLeft {
  background-color: black;
}

.MuiTablePagination-selectLabel {
  margin: 0px;
}

.MuiTablePagination-displayedRows {
  margin: 0px;
}

.bredcrumb_img ul li {
  list-style: none;
}
.bredcrumb_img ul li a {
  color: #959595 !important;
  font-weight: 600;
}
.bredcrumb_img ul li a.active {
  color: #03c103 !important;
}
.bredcrumb_img ul {
  display: flex;
  gap: 10px;
}
.search_table {
  background-color: white;
  border: 1px solid #00ee00;
  border-radius: 30px;
  display: inline-block;
  overflow: hidden;
}
.search_table input {
  border: none;
  width: 300px;
  padding: 6px;
}
.search_table input:focus {
  outline: none;
}

.search_table button {
  width: 38px;
  height: 38px;
  border-radius: 100%;
  color: black;
  background-color: #00ee00;
  border: none;
}

.global_check {
  background-color: #eff1f9;
  border-radius: 16px;
  box-shadow: none;
}

.muliti_check {
  box-shadow: none !important;
}

.muliti_check .MuiSelect-outlined{ padding: 13px;}

.global_check label{ top: 0px;}

.muliti_check:hover .MuiOutlinedInput-notchedOutline {
  border-color: #e7e7e7;
}

.global_check #demo-multiple-checkbox{ padding: 13px!important;}

.global_check .demo-multiple-checkbox {
  height: 47px;
  border-radius: 16px;
  box-shadow: none;
}

.global_check fieldset {
  border-radius: 16px;
  box-shadow: none;
  border: 1px solid #E7E7E7;
}

.upload_file{ background-color: black; padding: 15px; border-radius: 5px; color: white; font-weight: 500;}

.manage_btn button{ background-color: transparent;border: none; color: #595858; }
.manage_btn{ display: flex; align-items: center; gap: 15px; justify-content: end;}

@media screen and (max-width: 600px) {

  .que_anyltics img {

    width: 250px!important;

  }

}



.tgoggle label {
  position: relative;
  width: 45px;
  height: 24px;
  display: inline-block;
  background: #666666;
  border-radius: 30px;
  cursor: pointer;
  transition: all 0.3s;
  -moz-transition: all 0.3s;
  -webkit-transition: all 0.3s; }

.tgoggle label:after {
  content: "";
  position: absolute;
  left: 2px;
  top: 2px;
  width: 20px;
  height: 20px;
  background: #FFF;
  border-radius: 50%;
  box-shadow: 1px 3px 6px #666666;
}

.tgoggle input:checked + label {
  background: #009900; }

.tgoggle input:checked + label:after {
  left: auto;
  right: 2px; }

  .set_switch li{ display: flex; justify-content: space-between; font-weight: 600; margin-bottom: 10px;}

.toggle_space{ background-color: #CFFCCF; }

.toggle_space li{ display: flex; justify-content: space-between; font-weight: 600; margin-bottom: 15px;}
.logour_side{

  position: absolute;
    bottom: 0px;
    width: 100%;
}
.logour_side button { background-color: #00ee00; width: 100%;border: none; color: black; font-weight: 600; padding: 10px;transition: 0.6s;} 

.logour_side button:hover{ background-color: #000; color: white; transition: 0.6s;}

.css-jjtu05{ position: relative;}

@media screen and (max-width: 600px) {
.search_table input {
  width: 283px;
}
}

.navy a{ padding: 10px; display: block;}
.navy a:hover{ background-color: #b3b3b324;}
.navy button:hover{  background-color: #b3b3b324;}
.navy li{ border-top: 1px solid #D9D9D9;}

.navy button{padding: 10px; display: block; border: none; width: 100%; background-color: transparent; text-align: left; }

@media screen and (max-width: 767px) {
  .about_sec { display: none;}

  .my_chart{ width: 250px!important; min-height: 100px!important;}

}

@media screen and (max-width: 1450.98px) {

  .global_btn {

    padding: 8px 15px!important;

    font-size: 14px!important;
}

  .que_anyltics img{ width: 200px!important;}

  .admin_header header{ width: 100%;}

}

.table_samepattern .MuiTableContainer-root {
  min-height: 300px;
}

.apexcharts-legend-text{ color: white!important;}

.dropdown-toggle::after{content: unset!important;}

.dropdown-custom .dropdown-item{
  color: #878787 ! Important;
  font-size: 15px;

}

.dropdown-custom .dropdown-item svg{ font-size: 20px;}

.dropdown-item.active, .dropdown-item:active {
  background-color: #fff!important;
}